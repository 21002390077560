import React, { Component } from 'react';
import Img from 'gatsby-image';
import './GatsbyImage.scss';

export default class GatsbyImage extends Component {
  render() {
    const { image, className = '' , position = "absolute"} = this.props;
    if (!image) return <div className={`gatsby-image placeholder ${className}`} />;
    if ( image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid) {
        return <Img
        className={`gatsby-image ${className}`}
        fluid={image.localFile.childImageSharp.fluid}
        style={{
           position: position,
           left: 0,
           top: 0,
           width: "100%",
           height: "100%"
         }}
        />;
    }
    if (image.url) {
      return <img className={`image ${className}`} src={image.url} alt={image.alt || ''} />;
    }
    if (image.source_url) {
      return <img className={`image ${className}`} src={image.source_url} alt={image.alt || ''} />;
    }
    return (
      <div
        className={`gatsby-image placeholder ${className}`}
        style={{
           position: position,
           left: 0,
           top: 0,
           width: "100%",
           height: "100%"
         }}
      />
    );
  }
}
