import React, { Component } from 'react';
import GatsbyLink from '../GatsbyLink';
import GatsbyImage from '../GatsbyImage';
import { decodeEntities } from '../../utils/htmlParse'
import Image from '../Image';
import './CaseStudiesList.scss';
import CaseStudiesNavigation from './CaseStudiesNavigation'

const ListItem = (props) => {
  const { title, slug, featuredImage, location, sector, acf } = props.item
  
  const locationString = location.map( l => props.location.edges.find(e => e.node.wordpress_id === l ).node.name ).join(', ');
  const sectorString = decodeEntities(sector.map( l => props.sector.edges.find(e => e.node.wordpress_id === l ).node.name ).join(', '));
  
  return (
    <div className="flex-item">
      <GatsbyLink
        to={`/case-studies/${slug}/`}
        decode={false}
        className="flex-item__link"
      >
        <div className="image-container">
          <Image className="featured-image" image={featuredImage ? featuredImage : ''} />
        </div>
        <div className="text-container">
          <div className="text-wrapper">
            <span className="title" dangerouslySetInnerHTML={{__html: title}} />
            { locationString.length > 1 ? <span className="location">{locationString}</span> : ''}
            { sectorString.length > 1 ? <span className="sector">{sectorString}</span> : ''}
          </div>
          {/* <div className="read-more">
            <div className="overflow-hidden">
              <div className="button arrow">View Project</div>
            </div>
          </div> */}
          <a className="button">View Project</a>
        </div>
    </GatsbyLink>
    </div>
  )
}

export default class CaseStudiesList extends Component {
  render() {
    const {
      range,
      perPage,
      data,
      caseStudies
    } = this.props;
    const {
      location,
      sector,
    } = data
    if (!caseStudies || caseStudies.length < 1) return (
      <>
      <CaseStudiesNavigation location={location} sector={sector}  />
      <section className="CaseStudiesList">
        <div className="content-overlay">
          <div className="flex-row">
            <span>There are no case studies to show</span>
          </div>
        </div>
      </section>
      </>
    )
    return (
      <>
      <CaseStudiesNavigation location={location} sector={sector} caseStudies={caseStudies}  />
      <section className="CaseStudiesList">
        <div className="content-overlay">
          <div className="flex-row">
            {caseStudies.map((caseStudy, index) => {
              // console.log(caseStudy.node);
              if ( index >= (range - perPage) && index < range) {
                return (
                  <ListItem key={index} item={caseStudy.node} index={index} location={location} sector={sector} />
                )
              }
              if (!range && !perPage){
                return (
                  <ListItem key={index} item={caseStudy.node} index={index} location={location} sector={sector} />
                )
              }
            })}
            <div className="flex-item hide-this"></div>
            <div className="flex-item hide-this"></div>
          </div>
        </div>
      </section>
      </>
    );
  }
}
