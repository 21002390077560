import React from 'react'
import { Link } from 'gatsby'
import './Pagination.scss';

const Pagination = ({ pageContext, pathPrefix }) => {
  if (!pageContext) {
    return null;
  }
  // console.log(pageContext);
  const { previousPagePath = null, nextPagePath = null, numberOfPages = null, humanPageNumber = null } = pageContext
  var pages = [];
  for (var i = 0; i < numberOfPages; i++) {
        pages.push(i+1);
  }
  return (
    <nav className="pagination" role="navigation">
      <div className="navbar navbar-menu">
        {/* {previousPagePath && (
          <Link to={previousPagePath} className="prev">Prev</Link>
        )} */}
        {pages.map((key, index) => (
          <Link key={index} to={key !== 1 ? `${pathPrefix}/page/${key}/` : pathPrefix } activeClassName="active" className={ key == humanPageNumber ? `active` : ''}>{key}</Link>
        ))}
        {/* {nextPagePath && (
          <Link to={`${nextPagePath}/`} className="next">Next</Link>
        )} */}
      </div>
    </nav>
  )
}

export default Pagination
