import React from 'react'
import PropTypes from 'prop-types';
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import CaseStudiesList from '../components/CaseStudies/CaseStudiesList'
import CaseStudiesNavigation from '../components/CaseStudies/CaseStudiesNavigation'
import Pagination from '../components/Pagination'
import { decodeEntities } from '../utils/htmlParse'
import GatsbyLink from '../components/GatsbyLink'
import { slugify, isClient, getUrlVars, compileQuery } from '../utils/helpers'
import { Hero, Cta } from '../acf';
// import '../acf/ProjectsFeed.scss';

export default class IndexPage extends React.Component {
  render() {
    const {
      data,
      pageContext
    } = this.props;
    const {
      page,
      caseStudies
    } = data;
    const {
      pageTitle,
      yoast,
      acf
    } = page;
    const {
      subheading,
      heading,
      blurb,
      backgroundImage,
      isCaseStudy
    } = acf.layout[0];

    // console.log(acf.layout[0]);

    if (isClient) {
      var params = getUrlVars()
    }
    return (
      <Layout location={this.props.location} pageTitle={pageTitle}>
        <SEO
          title={pageTitle}
          desc={yoast.metaDescription}
        />
        <Hero subheading={subheading} heading={heading} blurb={blurb} backgroundImage={backgroundImage} />
        <div className="case-studies-list-container">
          <div className="wrapper">
            <CaseStudiesList data={data} caseStudies={caseStudies.edges} />
            <Pagination pageContext={pageContext} pathPrefix={`/case-studies`} />
          </div>
        </div>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    caseStudies: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query CaseStudiesPageQuery($limit: Int!, $skip: Int!) {
    page: wordpressPage(slug: {eq: "case-studies"}) {
      yoast {
        metadesc
        title
      }
      pageTitle: title
      acf {
        layout: layout_page {
          __typename,
          ... on WordPressAcf_Hero {
            id
            subheading
            heading
            blurb
            backgroundImage {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90, srcSetBreakpoints: [ 1600 ]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              },
              alt_text,
            }
          }
        }
      }
    }
    caseStudies: allWordpressWpCaseStudies (
      limit: $limit
      skip: $skip
      sort: {fields: acf___isFeatured, order: ASC}
      ) {
      edges {
        node {
          slug
          title
          location
          sector
          acf {
            isFeatured
          }
          featuredImage: featured_media {
            source_url
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          wordpress_id
          wordpress_parent
        }
      }
    }
    sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          wordpress_id
          wordpress_parent
        }
      }
    }
  }
`
